import {
  SlButton
} from "./chunk.KEXJVXAU.js";

// src/components/button/button.ts
var button_default = SlButton;
SlButton.define("sl-button");

export {
  button_default
};
