import {
  SlInput
} from "./chunk.NS24TQAP.js";

// src/components/input/input.ts
var input_default = SlInput;
SlInput.define("sl-input");

export {
  input_default
};
